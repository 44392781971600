<script>
import Layout from "../../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import RepasLieuform from "./components/RepasLieuform.vue";

import { fetchLieuRepasApi } from "@/api/common";
import Swal from "sweetalert2";
export default {
  import: {fetchLieuRepasApi},
  page: {
    title: "Lieu de Repas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, RepasLieuform },
  data() {
    return {
      selectedLieuRepas: null,
      tableData: [],
      List_LieuRepas: [],
      rows:'',
      active:false,
      selectedRows:[],
      lieuRepas:undefined,
      title: "Lieus de Repas",
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text :"Restauration",
          href : "/"
        },
        {
          text: "Lieus de repas",
          active: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    }
  },
     mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    this.fetchRepasLieuList();
  },
   watch:{
      selectedRows(){
        console.log(this.selectedRows);
      },
      lieuRepas:{
          handler(val){
            this.List_LieuRepas.push({
                name:val.name,
               // description:val.description  
            });
            console.log(this.List_LieuRepas);
          }
          
          
      }
  },
  methods:{
    fetchRepasLieuList(){
      fetchLieuRepasApi()
      .then(res => (this.data = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getLieuRepas(){
      this.$http.get('/base/restauration/LieuRepas/list')
      .then(response => {
        console.log(response.data);
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshLieuRepas(){
      this.getLieuRepas();
    },
     deleteLieuRepas(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/base/restauration/LieuRepas/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchRepasLieuList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    editLieuRepas(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/base/restauration/LieuRepas/getSingleLieuRepasData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var lieuRepas= res.data.original.data.lieuRepas;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedLieuRepas = lieuRepas;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadLieuRepasDataToFormComponent(selectedLieuRepas){
      if(selectedLieuRepas){
        this.editLieuRepas(selectedLieuRepas.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedLieuRepas.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedLieuRepas.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedLieuRepas.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedLieuRepas.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedLieuRepas.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(lieuRepas){

      this.currentSelectedLieuRepas = lieuRepas;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshLieuRepasTable = true;
      // jump to next tab
      this.formStep = 1;
    },
  }
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
       <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des types </div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                  <v-table
                    class="table table-hover"
                    :data="List_LieuRepas"
                    selectionMode="single"
                    selectedClass="table-info"
                     @selectionChanged="lieuTableRowSelected($event)"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Désignation</th>
                      <th>Actions</th>

                    </tr>
                  </thead>
                  <tbody slot="body" >
                    <v-tr v-for="row in data" :key="row.id" :row="row">
                      <td>
                        {{ data.name }}
                      </td>
                      <td>
                        
                        <a
                          @click="editLieuRepas(row.uuid)"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier"
                          data-original-title="Edit"
                          ><i class="mdi mdi-pencil font-size-18"></i
                        ></a>

                        <a
                          @click="deleteLieuRepas(row.type, row.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td> 
                    </v-tr>
                  </tbody>
                </v-table>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <RepasLieuform :base="selectedRows"  :lieuRepas="currentSelectedLieuRepas"  />
        </div>
      </div>
    </div>
  </Layout>
</template>